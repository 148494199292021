import { type TeaserFragment, isArticleTeaserContent, isVideoTeaserContent } from '@hubcms/domain-cook';

export function getTeaserCdnId(teaserData: TeaserFragment): string | null {
  if (isVideoTeaserContent(teaserData.content)) {
    return teaserData.content.fields.cdnId || null;
  }

  if (isArticleTeaserContent(teaserData.content)) {
    const heroMedia = teaserData.content.heroMedia?.[0];
    if (heroMedia?.content?.__typename === 'Video' && heroMedia.content?.fields?.cdnId) {
      return heroMedia.content.fields.cdnId || null;
    }
  }

  return null;
}
