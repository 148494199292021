import type { HtmlGroupOptions } from '@hubcms/domain-storyblock';

import type { CreateStoryblockOptionsFn } from '../../domain/create-storyblock-options-fn';
import { getGroupLink } from '../../lib/getGroupLink';
import { mapToGroupOptionsRecord } from '../../lib/mapToGroupOptionsRecord';
import { getIsHiddenOnMobile } from '../getIsHiddenOnMobile';

export const createHtmlGroupOptions: CreateStoryblockOptionsFn<HtmlGroupOptions> = ({ group, sectionParams }) => {
  const groupOptions = mapToGroupOptionsRecord(group.groupOptions);

  return {
    type: 'HtmlGroup',
    isHiddenOnMobile: getIsHiddenOnMobile(groupOptions),
    title: groupOptions['groupTitle'],
    actionLink: getGroupLink(groupOptions, 'groupTagLink', 'groupSectionLink', 'groupFreeLink'),
  };
};
