import type {
  StoryblockAreas,
  StoryblockFlattenListFn,
  StoryblockGridModifierFn,
  StoryblockPreFlightFn,
  TStoryblock,
} from '@hubcms/domain-storyblock';
import { isNonTeaserList, type TeaserData, type TeaserDataOrList } from '@hubcms/domain-teaser';
import type { TTeaserGrid } from '@hubcms/domain-teaser-grid';
import { mapRecord, pipeStrictType } from '@hubcms/utils-browser';
import { flattenArticleLists } from '@hubcms/utils-teaser';

import type { StoryblockGridData } from '../domain/storyblock-grid-data';

type ClientsideOptions = {
  preflightPipe: StoryblockPreFlightFn[];
  flattenListPipe: StoryblockFlattenListFn[];
  gridModificationPipe: StoryblockGridModifierFn[];
};

export function createGridDataFromStoryblock(
  storyblock: TStoryblock,
  { preflightPipe = [], flattenListPipe = [], gridModificationPipe = [] }: Partial<ClientsideOptions> = {},
): StoryblockGridData {
  const { groupId, theme, createGridData, storyblockOptions, unflattenedTeaserAreas } = pipeStrictType(storyblock, preflightPipe);

  const flattenedTeaserAreas = flattenTeaserAreas(unflattenedTeaserAreas, flattenListPipe);

  const hasNoTeasers = Object.values(flattenedTeaserAreas).every(teaserArea => teaserArea.length === 0);
  if (hasNoTeasers) {
    return buildStoryblockGridData({ groupId, theme, storyblockOptions }, { items: [] });
  }

  const initialGridData = createGridData(flattenedTeaserAreas, storyblockOptions);
  const gridData = pipeStrictType(initialGridData, gridModificationPipe);

  return buildStoryblockGridData({ groupId, theme, storyblockOptions }, gridData);
}

function flattenTeaserAreas(
  unflattenedTeaserAreas: StoryblockAreas<TeaserDataOrList>,
  flattenListPipe: StoryblockFlattenListFn[] = [],
): StoryblockAreas<TeaserData> {
  const fullPipe = [flattenArticleLists].concat(flattenListPipe);
  return mapRecord(unflattenedTeaserAreas, unflattenedArea => {
    return pipeStrictType(unflattenedArea, fullPipe).filter(isNonTeaserList);
  });
}

function buildStoryblockGridData(
  {
    groupId,
    theme,
    storyblockOptions: { type: groupType, isHiddenOnMobile },
  }: Pick<TStoryblock, 'groupId' | 'theme' | 'storyblockOptions'>,
  gridData: TTeaserGrid,
): StoryblockGridData {
  return {
    gridData,
    groupId,
    groupType,
    isHiddenOnMobile,
    theme,
  };
}
