import { type ImageFieldsFragment, type OnPicture, isElementWithRelation } from '@hubcms/domain-cook';
import { type ImageOrientation, ImageOrientationMap } from '@hubcms/domain-images';
import { type ImageData, type ImageSource, type ImageStoryElement, createImageData } from '@hubcms/domain-story-elements';

import { extractElementFields } from '../element-renderer';
import { getCaptionData } from '../get-caption-data';

import type { ElementDataMapFnWithOptions } from './types';

type ElementFields = { caption: string; orientation: ImageOrientation; alignment: string };

export const mapImage: ElementDataMapFnWithOptions<'sectionParams' | 'isExtended', ImageStoryElement> = (
  data,
  { sectionParams, isExtended },
) => {
  const imageRelation = isElementWithRelation<OnPicture>(data) ? data.relation : null;

  if (!imageRelation || imageRelation.__typename !== 'Picture') {
    return null;
  }

  const { caption: alt, orientation, alignment } = extractElementFields<ElementFields>(data);
  let imageOrientation = orientation;
  let imageOrientationData = ImageOrientationMap[imageOrientation];

  if (!imageOrientationData) {
    imageOrientation = 'SIXTEEN_NINE';
    imageOrientationData = ImageOrientationMap[imageOrientation];
  }
  const [aspectRatio] = imageOrientationData;
  const { originalWidth, originalHeight, url } = createImageSource(imageRelation.fields[aspectRatio]);
  const { caption, credit } = getCaptionData(data, sectionParams['image.credit.prefix']);
  const thumb = imageRelation.fields.oneOne;

  const imageData = createImageData({
    url,
    alt,
    thumbUrl: thumb.href_full,
    originalWidth,
    originalHeight,
    orientation: imageOrientation,
    caption,
    credit,
    alignment,
  });

  if (isExtended) {
    imageData.alternatives = createAlternatives(imageRelation);
    imageData.backgroundPosition = calculateBackgroundPosition(imageRelation);
  }
  return { ...imageData, imageMediaProps: { srcSetConstraints: [375, 1600] } };
};

function createImageSource(source: ImageFieldsFragment): ImageSource {
  if ((source.width === -1 || source.height === -1) && source.originalDimensions) {
    return {
      url: source.href_full,
      originalWidth: source.originalDimensions.width,
      originalHeight: source.originalDimensions.height,
    };
  }
  return {
    url: source.href_full,
    originalWidth: source.width,
    originalHeight: source.height,
  };
}

function createAlternatives(imageRelation: OnPicture): ImageData['alternatives'] {
  return {
    fourFive: createImageSource(imageRelation.fields.fourFive),
    fourThree: createImageSource(imageRelation.fields.fourThree),
    oneOne: createImageSource(imageRelation.fields.oneOne),
    sixteenNine: createImageSource(imageRelation.fields.sixteenNine),
    threeTwo: createImageSource(imageRelation.fields.threeTwo),
    twentyoneNine: createImageSource(imageRelation.fields.twentyoneNine),
    baseWidth: createImageSource(imageRelation.fields.baseWidth),
  };
}

function calculateBackgroundPosition(imageRelation: OnPicture): ImageData['backgroundPosition'] {
  try {
    const pictureMetadata = imageRelation.pictureMetadata;
    const parsedMetadata = JSON.parse(pictureMetadata ?? '{}');
    if (parsedMetadata['com.escenic.master']) {
      const focalPointHorizontal = (parsedMetadata.poi.left / parsedMetadata['com.escenic.master'].crop.width) * 100;
      const focalPointVertical = (parsedMetadata.poi.top / parsedMetadata['com.escenic.master'].crop.height) * 100;
      return `${focalPointHorizontal}% ${focalPointVertical}%`;
    }
    return null;
  } catch (err) {
    // ignore the error as it means there was no POI data
    return null;
  }
}
