import cx from 'classnames';

import styles from './takeover-ad-wallpaper.module.scss';

type TakeoverAdWallpaperProps = {
  className?: string;
};

export function TakeoverAdWallpaper({ className }: TakeoverAdWallpaperProps) {
  return (
    <div id="ad_wallpaper-t1" className={cx(className, styles.takeoverAdWallpaper, 'ad', 'ad--wallpaper')} data-ad="wallpaper" />
  );
}
