import type { RATING_ICON_MAP, RatingElement } from '@hubcms/domain-story-elements';

import { extractElementFields } from '../element-renderer';

import type { ElementDataMapFn } from './types';

export const mapRating: ElementDataMapFn<RatingElement> = data => {
  const { score, maximumScore, icon } = extractElementFields<{
    score: string;
    maximumScore: string;
    icon: keyof typeof RATING_ICON_MAP;
  }>(data);

  return {
    score: Number(score),
    maxScore: Number(maximumScore),
    icon,
  };
};
