import type { MySectionConfiguration } from '../domain/my-section-configuration';
import type { MySectionSelections } from '../domain/my-section-selections';

import {
  getSavedMySectionPreferences,
  getSavedMySectionSelection,
  saveMySectionPreferences,
  saveMySectionSelections,
} from './mySectionCookies';

export function updateMySectionCookies(mySectionConfiguration: MySectionConfiguration) {
  // mandatory my-section-prefs cookie
  const parsedSectionUrl = new URL(mySectionConfiguration.sectionUrl);
  const parsedChosenPreferenceUrl = new URL(mySectionConfiguration.chosenPreferenceUrl);

  const cookieValue = getSavedMySectionPreferences();
  cookieValue[parsedSectionUrl.toString()] = parsedChosenPreferenceUrl.toString();
  saveMySectionPreferences(cookieValue);

  // opt-in my-section-selection cookie with additional selection metadata
  if (mySectionConfiguration.storeMySectionSelectionInCookie) {
    const mySectionSelectionEntries = getSavedMySectionSelection();

    // filter out the current selection if it exists
    const filteredMySectionSelectionEntries = mySectionSelectionEntries.filter(
      selection => selection.type !== mySectionConfiguration.type,
    );

    // create new entry for selection
    const newMySectionSelectionEntry: MySectionSelections = {
      type: mySectionConfiguration.type,
      [mySectionConfiguration.type]: mySectionConfiguration.selectionName,
      [mySectionConfiguration.identifierLabel]: mySectionConfiguration.identifierValue,
    };

    saveMySectionSelections([...filteredMySectionSelectionEntries, newMySectionSelectionEntry]);
  }
}
