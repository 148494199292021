import { useSyncExternalStore, useCallback } from 'react';

import { isDidomiConsentEnabledByPurposeIdSync } from './is-didomi-consent-enabled-by-purpose-id';
import { onDidomiConsentChanged } from './on-didomi-consent-changed';
import { onDidomiReady } from './on-didomi-ready';

function subscribe(callback: () => void): () => void {
  onDidomiReady(callback);
  return onDidomiConsentChanged(callback);
}

function getServerSnapshot() {
  return undefined;
}

export function useDidomiConsent(purposeIds?: string | string[]) {
  const getSnapshot = useCallback(() => isDidomiConsentEnabledByPurposeIdSync(purposeIds), [purposeIds]);
  const snapshot = useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);
  const hasDidomiConsent = Boolean(snapshot);
  const isDidomiReady = typeof snapshot === 'boolean';

  return { hasDidomiConsent, isDidomiReady };
}
