import cx from 'classnames';
import type { PropsWithChildren } from 'react';

import styles from './takeover-ad-wallpaper-container.module.scss';

type TakeoverAdWallpaperContainerProps = PropsWithChildren<{
  className?: string;
}>;

export function TakeoverAdWallpaperContainer({ children, className }: TakeoverAdWallpaperContainerProps) {
  return <div className={cx(className, styles.takeoverAdWallpaperContainer)}>{children}</div>;
}
