import {
  type ElementsFragment,
  type OnAudioVisual,
  type OnStoryElementWithRelation,
  type SectionParams,
  isElementWithRelation,
} from '@hubcms/domain-cook';
import { type AudioVisualData, type AudioVisualStoryElement, createAudioVisualData } from '@hubcms/domain-story-elements';
import { getAgnoPlayerBrand } from '@hubcms/utils-agno-player';

import { getCaptionData } from '../get-caption-data';
import { getElementField } from '../get-element-field';

import type { ElementDataMapFnWithOptions } from './types';

export const mapAudiovisual: ElementDataMapFnWithOptions<'articleId' | 'sectionParams', AudioVisualStoryElement> = (
  data,
  { articleId, sectionParams },
) => {
  if (!isElementWithRelation<OnAudioVisual>(data) || !data.relation || !data.relation.fields) {
    return null;
  }

  const hasLargePodcastPlayer = getElementField<boolean>(data.fields, 'showLargePodcastPlayer');
  const agnoPlayerProperties = getAgnoPlayerProperties(data.relation, sectionParams, hasLargePodcastPlayer ?? false);
  const { caption, credit } = getAudioVisualCaptionData(data, sectionParams);
  const { autoplayOnView, noAdvertisement } = getPlayerProperties(data.relation);

  return createAudioVisualData({
    articleId,
    agnoPlayerBrand: agnoPlayerProperties.brand,
    agnoPlayerCdnId: agnoPlayerProperties.cdnId,
    agnoPlayerTheme: agnoPlayerProperties.theme,
    autoplayOnView,
    caption,
    credit,
    hasAdvertisementsSkipped: noAdvertisement,
  });
};

function getAgnoPlayerTheme(
  relationType: OnAudioVisual['type'],
  hasLargePodcastPlayer: boolean,
): AudioVisualData['agnoPlayerTheme'] {
  if (relationType !== 'podcast' || hasLargePodcastPlayer) {
    return 'advanced';
  }
  return 'slim';
}

function getAgnoPlayerProperties(relation: OnAudioVisual, sectionParams: SectionParams, hasLargePodcastPlayer: boolean) {
  const liveFeed = relation.__typename === 'LiveVideo' ? (relation.fields.liveFeed ?? undefined) : undefined;
  const brand = getAgnoPlayerBrand(relation.type, sectionParams, liveFeed);
  const cdnId = relation.__typename === 'LiveVideo' ? '' : (relation.fields.cdnId ?? '');
  const theme = getAgnoPlayerTheme(relation.type, hasLargePodcastPlayer);

  return {
    brand,
    cdnId,
    theme,
  };
}

function getPlayerProperties(relation: OnAudioVisual) {
  const { autoplayOnView, noAdvertisement } = relation.fields;
  return {
    autoplayOnView: autoplayOnView ?? false,
    noAdvertisement: noAdvertisement ?? false,
  };
}

function getAudioVisualCaptionData(
  data: ElementsFragment & OnStoryElementWithRelation<OnAudioVisual>,
  sectionParams: SectionParams,
) {
  return getCaptionData(
    data,
    sectionParams[`player.${data.relation?.type === 'podcast' ? 'audio' : 'video'}.credit.prefix`],
    [data.relation?.fields.caption ?? ''],
    [data.relation?.fields.credit ?? ''],
  );
}
