import type { PullQuoteStoryElement } from '@hubcms/domain-story-elements';

import { extractElementFields } from '../element-renderer';

import type { ElementDataMapFn } from './types';

export const mapPullQuote: ElementDataMapFn<PullQuoteStoryElement> = data => {
  const { quote, quotee, quoteeFunction } = extractElementFields<{ quote: string; quotee?: string; quoteeFunction?: string }>(
    data,
  );

  return {
    quote,
    quotee,
    quoteeFunction,
  };
};
