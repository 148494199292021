import { infoblockHeadingSize, articleHeadingSize } from '@hubcms/brand';
import { type ElementsFragment, isElementTextField } from '@hubcms/domain-cook';
import type { BaseAnnotatedTextData, HeadingData, HeadingStoryElement } from '@hubcms/domain-story-elements';

import type { AnnotationLinkElement } from '../../domain/AnnotationLinkElement';
import { getLinkElements } from '../annotation';
import { prepareAnnotations } from '../annotation/prepareAnnotations';
import { getElementField } from '../get-element-field';

import type { ElementDataMapFnWithOptions } from './types';

const sizeMap = new Map<string, HeadingData['size']>([
  ['Sm', 'sm'],
  ['Md', 'md'],
  ['Lg', 'lg'],
]);

export const mapHeading =
  (context: HeadingData['context'], isSubheading?: boolean): ElementDataMapFnWithOptions<'elements', HeadingStoryElement> =>
  (data, { elements }) => {
    const textField = data.fields?.find(({ name }) => name === getFieldName(context, isSubheading));
    const linkElements = getLinkElements(elements);
    const annotatedTextData = getAnnotatedTextData(textField, linkElements);
    const listicleField = getElementField<string>(data.fields, 'listicle') ?? null;
    const sizeField = getElementField<string>(data.fields, 'headingSizes') ?? null;

    return {
      id: data.id,
      ...annotatedTextData,
      level: getLevel(context, isSubheading),
      size: getSize(context, isSubheading, sizeField),
      context,
      isSubheading,
      listicle: getListicle(isSubheading, listicleField),
      charCount: annotatedTextData.text.length,
    };
  };

function getAnnotatedTextData(
  textField: ElementsFragment['fields'][number] | undefined,
  linkElements: AnnotationLinkElement[],
): BaseAnnotatedTextData {
  if (isElementTextField(textField)) {
    return {
      text: textField.value,
      annotations: prepareAnnotations(textField.value, textField.annotations ?? [], linkElements),
    };
  }
  return {
    text: '',
    annotations: [],
  };
}

function getLevel(context: HeadingData['context'], isSubheading?: boolean) {
  if (context === 'article') {
    return isSubheading ? 2 : 1;
  }
  if (isSubheading) {
    return 6;
  }
  return 5;
}

function getFieldName(context: HeadingData['context'], isSubheading?: boolean) {
  if (isSubheading) {
    return 'subhead';
  }
  if (context === 'infoblock') {
    return 'infoblockHeadline';
  }
  return 'headline';
}

function getSize(
  context: HeadingData['context'],
  isSubheading: boolean | undefined,
  sizeField: string | null,
): HeadingData['size'] {
  if (isSubheading) {
    return mapSizeFieldToSize(sizeField);
  }
  return context === 'infoblock' ? infoblockHeadingSize : articleHeadingSize;
}

function mapSizeFieldToSize(sizeField: string | null): HeadingData['size'] {
  if (sizeField) {
    return sizeMap.get(sizeField) || 'md';
  }

  return 'md';
}

function getListicle(isSubheading: boolean | undefined, listicleField: string | null): HeadingData['listicle'] {
  if (!isSubheading) {
    return null;
  }
  switch (listicleField) {
    case 'reset':
    case 'continue':
      return listicleField;
    default:
      return null;
  }
}
