import { type ElementsFragment, isElementWithChildren, isElementWithRelation } from '@hubcms/domain-cook';
import { type HeroMediaStoryElement, type StoryElement, type StoryElementType } from '@hubcms/domain-story-elements';
import { isValidStoryElement } from '@hubcms/utils-story-elements';

import type { StoryDataWithElements } from '../domain/story-data';

const VALID_HERO_MEDIA_STORY_ELEMENT_TYPES: StoryElementType[] = ['gallery', 'image', 'audiovisual'];
const HERO_MEDIA_COOK_ELEMENT_TYPES: StoryElementType[] = ['gallery', 'image', 'hero_media'];

export function findHeroMediaId(articleData: StoryDataWithElements): string | null {
  const element = articleData.elements.find(
    element => HERO_MEDIA_COOK_ELEMENT_TYPES.includes(element.type as StoryElementType) && elementHasContent(element),
  );
  return element?.id ?? null;
}

function elementHasContent(element: ElementsFragment) {
  if (isElementWithRelation(element) && element.relation !== null) {
    return true;
  }
  if (isElementWithChildren(element) && element.children.length > 0) {
    return true;
  }
  return element.fields.length > 0;
}

export function validateHeroMedia(storyElement: StoryElement | undefined) {
  if (!isValidStoryElement<HeroMediaStoryElement>(VALID_HERO_MEDIA_STORY_ELEMENT_TYPES)(storyElement)) {
    return null;
  }
  return storyElement;
}
