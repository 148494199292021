import { useMemo } from 'react';

import { useCook } from '@hubcms/data-access-cook';
import type { QueryResultData } from '@hubcms/domain-cook';
import type { StoryblockPreFlightFn } from '@hubcms/domain-storyblock';
import type { TeaserPropsOptions } from '@hubcms/domain-teaser';
import { useMySectionStoryblockSubtitle } from '@hubcms/utils-my-section';

import { replaceDeskedTeasersWithMySectionData } from '../utils/replace-desked-teasers-with-my-section-data';

export function useMySectionStoryblock(teaserPropsOptions: TeaserPropsOptions) {
  const { sectionParams, resolution } = useCook<QueryResultData>();

  const { addMySectionSubtitle } = useMySectionStoryblockSubtitle(
    resolution?.section?.name,
    resolution?.section?.href,
    sectionParams,
  );

  const mySectionPreflight = useMemo(() => {
    return getMySectionDataForGroup(teaserPropsOptions);
  }, [teaserPropsOptions]);

  return {
    addMySectionSubtitle,
    mySectionPreflight,
  };
}

function getMySectionDataForGroup(teaserPropsOptions: TeaserPropsOptions): StoryblockPreFlightFn {
  return function (storyblock) {
    const { storyblockOptions, unflattenedTeaserAreas } = storyblock;

    if (storyblockOptions.type === 'MySectionGroup') {
      const { newGroupOptions = {}, newTeaserAreas = {} } = replaceDeskedTeasersWithMySectionData(
        storyblockOptions,
        unflattenedTeaserAreas,
        teaserPropsOptions,
      );
      return {
        ...storyblock,
        unflattenedTeaserAreas: { ...unflattenedTeaserAreas, ...newTeaserAreas },
        storyblockOptions: { ...storyblockOptions, ...newGroupOptions },
      };
    }
    return storyblock;
  };
}
