import cx from 'classnames';

import type { TStoryblock } from '@hubcms/domain-storyblock';
import type { TeaserPropsOptions } from '@hubcms/domain-teaser';
import { Ad, CbaAd, ScrollableAds } from '@hubcms/feature-ads';
import { StoryblockList } from '@hubcms/feature-storyblock';
import { isCbaAd } from '@hubcms/utils-ads';

import styles from './aside-recirculation.module.scss';

type AsideRecirculationProps = {
  storyblocks: TStoryblock[];
  teaserPropsOptions: TeaserPropsOptions;
  hasNoAds?: boolean;
  adPositions?: number[];
  adFormats?: string[];
  className?: string;
};

export default function AsideRecirculation({
  storyblocks,
  teaserPropsOptions,
  hasNoAds = true,
  adPositions = [],
  adFormats = [],
  className,
}: AsideRecirculationProps) {
  const canDisplayAds = !hasNoAds && adFormats.length > 0 && adPositions.length > 0;
  const showAd = storyblocks.length === 0 && canDisplayAds;

  if (storyblocks.length === 0 && !canDisplayAds) {
    return null;
  }

  return (
    <div className={cx({ [styles.AsideRecirculation]: !hasNoAds }, className, 'no-print')}>
      {storyblocks.length > 0 && (
        <StoryblockList
          storyblocks={storyblocks}
          adPositions={adPositions}
          adFormats={adFormats}
          adSlotPrefix="recirculation"
          isAside
          teaserPropsOptions={teaserPropsOptions}
          getAdElement={index => (index === storyblocks.length - 1 ? ScrollableAds : Ad)}
        />
      )}
      {showAd && (isCbaAd(adFormats[0]) ? <CbaAd /> : <Ad adSlot="b" adFormat={adFormats[0]} adSlotPrefix="recirculation" />)}
    </div>
  );
}
