import type { SectionParams } from '@hubcms/domain-cook';
import type { LiveBlogStoryElement } from '@hubcms/domain-story-elements';

import { getElementField } from '../get-element-field';

import type { ElementDataMapFnWithOptions } from './types';

export const mapLiveBlogData: ElementDataMapFnWithOptions<'sectionParams', LiveBlogStoryElement> = (data, { sectionParams }) => {
  const liveblogId = getElementField<string>(data.fields, 'liveBlogId') ?? '';
  const imageCreditPrefix = sectionParams['image.credit.prefix'];
  const tickarooThemeId = sectionParams['tickaroo.themeid'];

  const facebook = getPurposeIds(sectionParams, 'facebook');
  const instagram = getPurposeIds(sectionParams, 'instagram');
  const tikTok = getPurposeIds(sectionParams, 'tiktok');
  const x = getPurposeIds(sectionParams, 'twitter');
  const vimeo = getPurposeIds(sectionParams, 'vimeo');
  const youtube = getPurposeIds(sectionParams, 'youtube');

  return {
    liveblogId,
    tickarooThemeId,
    imageCreditPrefix,
    purposeIds: {
      facebook,
      instagram,
      tikTok,
      x,
      vimeo,
      youtube,
    },
  };
};

const getPurposeIds = (sectionParams: SectionParams, provider: string) => {
  return (sectionParams[`embed.gdpr.${provider}.requiredconsents`]?.split(',') ?? []).map(purposeId => purposeId.trim());
};
