import type { GList } from '@hubcms/domain-teaser-grid';
import { hasSubtheme } from '@hubcms/vendor/chameleon/utils-chameleon';

import type { ListOptions } from '../domain/list-options';

function getTheme() {
  if (hasSubtheme('accent-soft')) {
    return 'accent-soft';
  }
  if (hasSubtheme('accent')) {
    return 'accent';
  }
  return null;
}

export function createListData(
  listItems: GList['data']['items'],
  { listLayout, isListWithMobileSlider, hasImages, sliderTeaserOrientation, isHighlighted }: ListOptions,
  lgBlockHeight = 1,
): GList {
  let hasXsItemSeparator = false;
  if (isListWithMobileSlider) {
    hasXsItemSeparator = !hasImages || sliderTeaserOrientation === 'horizontal';
  }
  if (!isListWithMobileSlider && hasImages) {
    hasXsItemSeparator = true;
  }

  const theme = isHighlighted ? getTheme() : null;

  const listArea: GList = {
    type: 'list',
    data: {
      layout: listLayout,
      isOrdered: listLayout === 'Numbering',
      verticalAlign: 'top',
      listDirection: isListWithMobileSlider
        ? {
            xs: 'row',
            md: 'column',
            lg: 'column',
          }
        : 'column',
      hasItemSeparator: {
        xs: hasXsItemSeparator,
        md: hasImages,
        lg: hasImages,
      },
      items: listItems,
      ...(theme ? { theme } : null),
    },
    gridProps: {
      rowLg: `span ${lgBlockHeight}`,
      hasInverseMarginInlineLg: false,
    },
  };
  if (isListWithMobileSlider) {
    listArea.gridProps = {
      ...listArea.gridProps,
      hasInverseMarginInlineSm: true,
    };
  }

  if (isListWithMobileSlider) {
    listArea.data.scroll = {
      breakpoints: {
        xs: true,
        sm: true,
        md: false,
        lg: false,
        xl: false,
      },
    };
  }

  return listArea;
}
