import cx from 'classnames';
import { type PropsWithChildren, type ElementType, useState, useCallback } from 'react';

import { isLeaderboardAdHiddenOnMobile } from '@hubcms/brand';
import type { KachingRenderInitData } from '@hubcms/domain-ads';
import {
  LeaderboardAd,
  TakeoverAdContentContainer,
  TakeoverAdWallpaperContainer,
  TakeoverAdWallpaper,
} from '@hubcms/feature-ads';

import styles from './page-layout.module.scss';

type PageLayoutBreakpoint = 'xs' | 'md';

type PageLayoutProps = PropsWithChildren<{
  as?: ElementType;
  leadingAdFormat?: string;
  hasLeadingSpaceIfNoLeadingAd: boolean | Record<PageLayoutBreakpoint, boolean>;
}>;

const isLeaderboardAdVisibleOnMobile = !isLeaderboardAdHiddenOnMobile;

function isBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean';
}

export function PageLayout({
  as: Component = 'main',
  children,
  hasLeadingSpaceIfNoLeadingAd,
  leadingAdFormat = '',
}: PageLayoutProps) {
  // By default, an ad is a placeholder and is considered an ad with content.
  // Only when the ad request is successful and the ad is empty, it is considered an ad without content.
  const [isLeadingAdWithContent, setIsLeadingAdWithContent] = useState(true);

  const hasLeadingAdFormat = leadingAdFormat !== '';

  const hasLeadingAd: Record<PageLayoutBreakpoint, boolean> = {
    xs: hasLeadingAdFormat && isLeaderboardAdVisibleOnMobile && isLeadingAdWithContent,
    md: hasLeadingAdFormat && isLeadingAdWithContent,
  };

  const handleLeadingAdRenderInit = useCallback(({ isEmpty }: KachingRenderInitData) => {
    setIsLeadingAdWithContent(!isEmpty);
  }, []);

  return (
    <Component
      className={cx(styles.pageLayout, {
        [styles.hasLeadingAdXs]: hasLeadingAd.xs,
        [styles.hasLeadingAdMd]: hasLeadingAd.md,
        [styles.hasLeadingSpaceIfNoLeadingAdXs]: isBoolean(hasLeadingSpaceIfNoLeadingAd)
          ? hasLeadingSpaceIfNoLeadingAd
          : hasLeadingSpaceIfNoLeadingAd.xs,
        [styles.hasLeadingSpaceIfNoLeadingAdMd]: isBoolean(hasLeadingSpaceIfNoLeadingAd)
          ? hasLeadingSpaceIfNoLeadingAd
          : hasLeadingSpaceIfNoLeadingAd.md,
      })}
    >
      {hasLeadingAdFormat && (
        <>
          <TakeoverAdWallpaperContainer className={styles.takeoverAdContainer}>
            <TakeoverAdWallpaper className={styles.takeoverAd} />
          </TakeoverAdWallpaperContainer>
          <TakeoverAdContentContainer className={styles.leadingAdContainer}>
            <LeaderboardAd adFormat={leadingAdFormat} className={styles.leadingAd} onRenderInit={handleLeadingAdRenderInit} />
          </TakeoverAdContentContainer>
        </>
      )}
      <TakeoverAdContentContainer className={styles.contentContainer}>{children}</TakeoverAdContentContainer>
    </Component>
  );
}
