import { brandCode } from '@hubcms/brand';
import type { Newsletter } from '@hubcms/domain-cook';
import { type NewsletterData, newsletterBrandMap, newsletterLocationMap } from '@hubcms/domain-newsletter';

import { parseSuggestions } from './parse-suggestions';

export function mapCookFieldsToNewsletterData({
  name,
  description,
  position,
  style,
  selectedNewsletter,
  suggestedNewsletters,
}: Newsletter['fields']): NewsletterData {
  return {
    newsletter: {
      id: selectedNewsletter,
      name,
      description,
    },
    showIcon: style === 'icon',
    location: newsletterLocationMap.get(position) || null,
    brand: newsletterBrandMap.get(brandCode) || null,
    suggestions: parseSuggestions(suggestedNewsletters),
  };
}
