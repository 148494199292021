import {
  type TeaserFragment,
  createTeaserFragment,
  isArticleListContent,
  isNewsflowListContent,
  isPersonalizedArticleListContent,
} from '@hubcms/domain-cook';
import { type TeaserList, type TeaserPropsOptions, createTeaserList } from '@hubcms/domain-teaser';
import { isNonNull } from '@hubcms/utils-browser';

import type { SingleTeaserFragment } from '../domain/single-teaser-fragment';

import { mapTeaserData } from './mapTeaserData';

export function mapTeaserList(teaserFragment: TeaserFragment, teaserPropsOptions: TeaserPropsOptions): TeaserList | null {
  if (isArticleListContent(teaserFragment.content)) {
    const listTeaserPropsOptions: TeaserPropsOptions = {
      ...teaserPropsOptions,
      list: {
        sourceType: 'article-list',
        id: teaserFragment.content.id,
      },
    };
    return createTeaserList({
      listType: 'article-list',
      id: teaserFragment.content.id,
      items: (teaserFragment.content.content ?? [])
        .map(content => createTeaserFragment({ content }))
        .map(childTeaserFragment => mapTeaserData(childTeaserFragment as SingleTeaserFragment, listTeaserPropsOptions))
        .filter(isNonNull),
      params: {},
      title: teaserFragment.content.title,
    });
  }

  if (isNewsflowListContent(teaserFragment.content)) {
    const listTeaserPropsOptions: TeaserPropsOptions = {
      ...teaserPropsOptions,
      list: {
        sourceType: 'news-flow-list',
        id: teaserFragment.content.id,
      },
    };
    return createTeaserList({
      listType: 'article-list',
      id: teaserFragment.content.id,
      items: (teaserFragment.content.content ?? [])
        .map(partialTeaserFragment => createTeaserFragment(partialTeaserFragment))
        .map(childTeaserFragment => mapTeaserData(childTeaserFragment as SingleTeaserFragment, listTeaserPropsOptions))
        .filter(isNonNull),
      params: {},
      title: teaserFragment.content.title,
    });
  }

  if (isPersonalizedArticleListContent(teaserFragment.content)) {
    return createTeaserList({
      listType: 'personalized-list',
      id: teaserFragment.content.id,
      items: [],
      params: teaserFragment.content.fields,
      title: teaserFragment.content.title,
    });
  }

  return null;
}
