import type { CustomizableStoryblockGroup, SectionParams } from '@hubcms/domain-cook';
import type { CustomizableGroupOptions } from '@hubcms/domain-storyblock';

import { getGroupLink } from '../../lib/getGroupLink';
import { mapToGroupOptionsRecord } from '../../lib/mapToGroupOptionsRecord';
import { getIsHiddenOnMobile } from '../getIsHiddenOnMobile';
import { getListLayout } from '../getListLayout';

type Options = Record<string, string>;

export function createCustomizableGroupAreaOptions(data: CustomizableStoryblockGroup, sectionParams: SectionParams) {
  return {
    areaOneOptions: mapToAreaOneOptions(data),
    areaTwoOptions: mapToAreaTwoOptions(data, sectionParams),
    areaThreeOptions: mapToAreaThreeOptions(data, sectionParams),
  };
}

function getIsHighlighted(options: Options) {
  return options.highlight === 'true';
}

function getIsStackedMobile(options: Options) {
  return options.layoutMobileApp === 'stacked';
}

function getHasImages(options: Options) {
  return options.hideImage !== 'true';
}

function getHasAd(options: Options) {
  return options.advertising === 'display-on-full';
}

function getHasCbaAd(options: Options) {
  return options.advertising === 'display-cba-on-last';
}

function mapToAreaOneOptions(data: CustomizableStoryblockGroup): CustomizableGroupOptions['areaOneOptions'] {
  const areaOneOptions = mapToGroupOptionsRecord(data.areaOneOptions);

  return {
    isDefaultTeaser: areaOneOptions['teaserType'] === 'default',
    isHighlighted: getIsHighlighted(areaOneOptions),
    hasIntroDesktop: areaOneOptions['showIntroDesktop'] === 'true',
    hasIntroMobile: areaOneOptions['showIntroMobile'] === 'true',
  };
}

function getCbaAdformat(sectionParams: SectionParams): string {
  return sectionParams['b2b.storyblock.customizable.cba.adformat'] || '';
}

function getFullAdFormat(sectionParams: SectionParams): string {
  return sectionParams['b2b.storyblock.Customizable.adformat'] || '';
}

function mapToAreaTwoOptions(
  data: CustomizableStoryblockGroup,
  sectionParams: SectionParams,
): CustomizableGroupOptions['areaTwoOptions'] {
  const areaTwoOptions = mapToGroupOptionsRecord(data.areaTwoOptions);

  return {
    cbaAdFormat: getCbaAdformat(sectionParams),
    fullAdFormat: getFullAdFormat(sectionParams),
    hasImages: getHasImages(areaTwoOptions),
    isAreaHiddenMobile: getIsHiddenOnMobile(areaTwoOptions),
    isHighlighted: getIsHighlighted(areaTwoOptions),
    isStackedMobile: getIsStackedMobile(areaTwoOptions),
    hasAd: getHasAd(areaTwoOptions),
    hasCbaAd: getHasCbaAd(areaTwoOptions),
  };
}

function mapToAreaThreeOptions(
  data: CustomizableStoryblockGroup,
  sectionParams: SectionParams,
): CustomizableGroupOptions['areaThreeOptions'] {
  const areaThreeOptions = mapToGroupOptionsRecord(data.areaThreeOptions);

  return {
    cbaAdFormat: getCbaAdformat(sectionParams),
    fullAdFormat: getFullAdFormat(sectionParams),
    title: areaThreeOptions.areaTitle,
    actionLink: getGroupLink(areaThreeOptions, 'areaTagLink', 'areaSectionLink', 'areaFreeLink'),
    actionText: sectionParams['section.group.readmore.text'],
    hasAd: getHasAd(areaThreeOptions),
    hasCbaAd: getHasCbaAd(areaThreeOptions),
    hasImages: getHasImages(areaThreeOptions),
    isAreaHiddenMobile: getIsHiddenOnMobile(areaThreeOptions),
    isHighlighted: getIsHighlighted(areaThreeOptions),
    isStackedMobile: getIsStackedMobile(areaThreeOptions),
    isList: areaThreeOptions['teaserType'] === 'list',
    listLayout: getListLayout(areaThreeOptions['teaserLayout']),
    isListWithMobileSlider: areaThreeOptions['mobileAppSlider'] === 'true',
  };
}
