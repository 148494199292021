import type { KachingRenderInitData } from '@hubcms/domain-ads';

import { LEADERBOARD_OBSERVE_TARGET_SELECTOR } from '../../utils/useLeaderboardResizeObserver';
import { Ad } from '../Ad/Ad';

type LeaderboardAdProps = {
  adFormat?: string;
  className?: string;
  onRenderInit?: (data: KachingRenderInitData) => void;
};

export const LeaderboardAd = ({ adFormat = 'leaderboard', className, onRenderInit }: LeaderboardAdProps) => (
  <div id={LEADERBOARD_OBSERVE_TARGET_SELECTOR}>
    <Ad adFormat={adFormat} adSlot="t1" className={className} onRenderInit={onRenderInit} />
  </div>
);
