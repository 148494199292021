import { type ListIdentifiers, mapRecommendationTeasersToTeaserData } from '@hubcms/data-access-capitan';
import type { RecommendationsResponse } from '@hubcms/domain-capitan';
import type { StoryblockFlattenListFn } from '@hubcms/domain-storyblock';
import type { TeaserPropsOptions } from '@hubcms/domain-teaser';
import { appendDefaultArticleTrackingData } from '@hubcms/utils-teaser';

import { isPersonalizedList } from '../domain/personalized-list';

import { createPlaceholders } from './create-placeholders';

export const mapPersonalizedArticleLists = (
  recommendationsResponse: RecommendationsResponse,
  teaserPropsOptions: TeaserPropsOptions,
  addPlaceholders = false,
): StoryblockFlattenListFn => {
  return teasers => {
    return teasers.flatMap(teaserDataOrList => {
      if (!isPersonalizedList(teaserDataOrList)) {
        return [teaserDataOrList];
      }

      // find matching personalized list in recommendations data
      const personalizedListData = recommendationsResponse.lists.find(
        ({ name: personalizedListName }) => personalizedListName === teaserDataOrList.title,
      );

      // map recommendations teasers to teaser fragments
      if (personalizedListData) {
        const listIdentifiers: ListIdentifiers = {
          recommender: recommendationsResponse.recommender,
          listId: teaserDataOrList.id,
        };
        return mapRecommendationTeasersToTeaserData(personalizedListData.teasers, listIdentifiers, teaserPropsOptions, {
          itemdescription: personalizedListData.name,
          recommendationsource: recommendationsResponse.recommender,
          recommendationrequestid: recommendationsResponse.requestId,
          recommendationsegmentgroup: recommendationsResponse.modelVersion,
        }).map(appendDefaultArticleTrackingData);
      }

      // map placeholders
      if (addPlaceholders) {
        return createPlaceholders(teaserDataOrList.params.maxCount);
      }

      return [];
    });
  };
};
