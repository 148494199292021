import type { StoryHeaderData } from '@hubcms/domain-story';
import type { HeadingStoryElement, ParagraphStoryElement, StoryElement } from '@hubcms/domain-story-elements';
import { isNonNull } from '@hubcms/utils-browser';
import { isValidStoryElement } from '@hubcms/utils-story-elements';

import { isStoryDataWithElements, isTagIntroData, type StoryCookData } from '../domain/story-data';

function getLabel(data: StoryCookData): string {
  if (isTagIntroData(data)) {
    return '';
  }
  if (data.fields.labelOverride) {
    return data.fields.labelOverride;
  }
  if (!isStoryDataWithElements(data) || data.fields.labelDropdown === 'none') {
    return '';
  }
  return data.fields.labelDropdown ?? '';
}

export function getStoryHeaderData(
  articleData: StoryCookData | null,
  storyElements: StoryElement[],
): {
  headerDataElementIds: string[];
  headerData: StoryHeaderData;
} {
  if (!articleData) {
    return {
      headerDataElementIds: [],
      headerData: {},
    };
  }

  if (isStoryDataWithElements(articleData)) {
    const headlineElement = storyElements.filter(isValidStoryElement<HeadingStoryElement>(['headline'])).pop();
    const headingData = headlineElement ? headlineElement.data : undefined;

    const introElement = storyElements.filter(isValidStoryElement<ParagraphStoryElement>(['intro'])).pop();
    const introData = introElement ? introElement.data : undefined;

    const locationElement = storyElements.filter(isValidStoryElement<ParagraphStoryElement>(['location'])).pop();
    const locationData = locationElement ? locationElement.data : undefined;

    const headerData: StoryHeaderData = {
      articleId: articleData.id,
      headingData,
      introData,
      locationData,
      label: getLabel(articleData),
    };
    if (!isTagIntroData(articleData)) {
      headerData.sublabel = articleData.fields.sublabel || '';
    }
    return {
      headerDataElementIds: [headlineElement?.id, introElement?.id, locationElement?.id].filter(isNonNull),
      headerData,
    };
  }

  return {
    headerDataElementIds: [],
    headerData: {
      articleId: articleData.id,
      headingData: {
        text: articleData.fields.title || '',
        annotations: [],
      },
      introData: {
        text: articleData.fields.intro || '',
        annotations: [],
      },
      label: getLabel(articleData),
      sublabel: articleData.fields.sublabel || '',
    },
  };
}
