import cx from 'classnames';
import type { CSSProperties } from 'react';

import { useScrollableAds } from '../../utils/useScrollableAds';
import { Ad, type AdProps } from '../Ad/Ad';

import styles from './scrollable-ads.module.scss';

type ScrollableAdsProps = Pick<AdProps, 'adFormat' | 'adStickyTop' | 'className'>;

export const ScrollableAds = ({ adFormat, adStickyTop = 0, className }: ScrollableAdsProps) => {
  const { actualAdHeight, maxAdSlotHeight, numberOfAdditionalSlots, setActualAdHeight, wrapperRef } = useScrollableAds();

  const adStickyTopString = typeof adStickyTop === 'number' ? `${adStickyTop}px` : adStickyTop;
  const containerStyle = {
    '--i-actual-ad-height': actualAdHeight,
    '--i-sticky-top-offset': adStickyTopString,
    '--i-max-slot-height': maxAdSlotHeight,
  } as CSSProperties;

  return (
    <div className={cx(styles.scrollableAds, className)} style={containerStyle} ref={wrapperRef}>
      <ScrollableAd key="firstScrollableAd" adFormat={adFormat} adStickyTop={adStickyTop} onAdLoaded={setActualAdHeight} />
      {Array.from({ length: numberOfAdditionalSlots }).map((_, i, arr) => {
        return <ScrollableAd key={`next-ad-${i + 1}`} adFormat={adFormat} adStickyTop={adStickyTop} />;
      })}
    </div>
  );
};

type ScrollableAdProps = {
  adFormat: string;
  adStickyTop: AdProps['adStickyTop'];
  onAdLoaded?: (height: number) => void;
};

function ScrollableAd({ adFormat, adStickyTop = 0, onAdLoaded }: ScrollableAdProps) {
  return (
    <div className={styles.adSlot}>
      <Ad
        adFormat={adFormat}
        adSlot="b"
        adSlotPrefix="recirculation"
        adStickyTop={adStickyTop}
        className={styles.scrollableAd}
        isSticky={true}
        onChangeHeight={onAdLoaded}
      />
    </div>
  );
}
