import { useEffect, useState } from 'react';

import type { TStoryblock } from '@hubcms/domain-storyblock';
import type { TeaserPropsOptions } from '@hubcms/domain-teaser';
import { useMySectionStoryblock } from '@hubcms/feature-my-section';
import { usePersonalizedArticleLists } from '@hubcms/feature-personalized-article-list';

import type { StoryblockGridData } from '../domain/storyblock-grid-data';

import { createGridDataFromStoryblock } from './createGridDataFromStoryblock';

export function useStoryblockGridData(storyblocks: TStoryblock[], teaserPropsOptions: TeaserPropsOptions) {
  const [storyblockGridData, setStoryblockGridData] = useState<StoryblockGridData[]>(
    createGridDataFromStoryblockList(storyblocks),
  );

  const { addMySectionSubtitle, mySectionPreflight } = useMySectionStoryblock(teaserPropsOptions);
  const { getFlattenPersonalizedListFn } = usePersonalizedArticleLists(storyblocks, teaserPropsOptions);

  useEffect(() => {
    const abortController = new AbortController();

    async function loadClientSideData() {
      const flattenPersonalizedLists = await getFlattenPersonalizedListFn(abortController.signal);

      const storyblockGridData = createGridDataFromStoryblockList(storyblocks, {
        preflightPipe: [mySectionPreflight],
        flattenListPipe: [flattenPersonalizedLists],
        gridModificationPipe: [addMySectionSubtitle],
      });

      setStoryblockGridData(storyblockGridData);
    }

    loadClientSideData();

    return () => {
      abortController.abort();
    };
  }, [storyblocks, addMySectionSubtitle, mySectionPreflight, getFlattenPersonalizedListFn]);

  return { storyblockGridData };
}

function createGridDataFromStoryblockList(
  storyblocks: TStoryblock[],
  options: Parameters<typeof createGridDataFromStoryblock>[1] = {},
) {
  return storyblocks
    .map(storyblock => createGridDataFromStoryblock(storyblock, options))
    .filter(({ gridData }) => gridData && gridData.items.length > 0);
}
