import { mapAnnotatedElement } from './mapAnnotatedElement';
import { mapAudiovisual } from './mapAudiovisual';
import { mapCodeBlock } from './mapCodeBlock';
import { mapGalleryData } from './mapGalleryData';
import { mapHeading } from './mapHeading';
import { mapHeroMedia } from './mapHeroMedia';
import { mapImage } from './mapImage';
import { mapInfoBlockGroup } from './mapInfoBlockGroup';
import { mapInterview } from './mapInterview';
import { mapList } from './mapList';
import { mapLiveBlogData } from './mapLiveBlogData';
import { mapMapsEmbed } from './mapMapsEmbed';
import { mapNewsLetterWidget } from './mapNewsLetterWidget';
import { mapPullQuote } from './mapPullQuote';
import { mapRating } from './mapRating';
import { mapRelation } from './mapRelation';
import { mapSocialEmbed } from './mapSocialEmbed';
import { mapSportsResult } from './mapSportsResult';
import { mapSportsWidget } from './mapSportsWidget';
import { mapTableData } from './mapTableData';
// eslint-disable-next-line import/max-dependencies
import type { ElementDataMap } from './types';

export const ELEMENT_DATA_MAP: ElementDataMap = {
  /* eslint-disable camelcase */
  audiovisual: mapAudiovisual,
  code_block: mapCodeBlock,
  embed: mapSocialEmbed,
  gallery: mapGalleryData,
  headline: mapHeading('article'),
  hero_media: mapHeroMedia,
  image: mapImage,
  infoblock_group: mapInfoBlockGroup,
  infoblock_headline: mapHeading('infoblock'),
  infoblock_image: mapImage,
  infoblock_list_bulleted: mapList(false),
  infoblock_list_numbered: mapList(true),
  infoblock_paragraph: mapAnnotatedElement('paragraph'),
  infoblock_sub_head: mapHeading('infoblock', true),
  sports_result: mapSportsResult,
  interview: mapInterview,
  interview_answer: mapAnnotatedElement('answer'),
  interview_question: mapAnnotatedElement('question'),
  intro: mapAnnotatedElement('intro'),
  list_bulleted: mapList(false),
  list_numbered: mapList(true),
  live_blog: mapLiveBlogData,
  location: mapAnnotatedElement('location'),
  map: mapMapsEmbed,
  mhbe_video: mapSocialEmbed,
  newsletter_widget: mapNewsLetterWidget,
  page_break: () => ({}),
  paragraph: mapAnnotatedElement('paragraph'),
  pull_quote: mapPullQuote,
  rating: mapRating,
  relation: mapRelation,
  sports_group: mapInfoBlockGroup,
  sports_widget: mapSportsWidget,
  sub_head: mapHeading('article', true),
  summary_group: mapInfoBlockGroup,
  table: mapTableData,
  /* eslint-enable camelcase */
};
