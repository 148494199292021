import type { ArticleTeaserData, HtmlTeaserData, NewsletterTeaserData, PlaceholderTeaserData } from '@hubcms/domain-teaser';
import { isNonNull } from '@hubcms/utils-browser';

import { createCbaAd } from '../../createCbaAd';

import { createCustomizableStoryblockTeaser } from './createCustomizableStoryblockTeaser';
import type { SubsequentAreaOptions } from './types';

export function createAreaWithCbaAds(
  teaserDataList: Array<HtmlTeaserData | ArticleTeaserData | PlaceholderTeaserData | NewsletterTeaserData>,
  areaOptions: SubsequentAreaOptions,
  isAreaOnePositionTop: boolean,
) {
  const teaser = teaserDataList[0]
    ? createCustomizableStoryblockTeaser(teaserDataList[0], areaOptions, { isAreaOnePositionTop })
    : null;
  const ad = createCbaAd(areaOptions.cbaAdFormat);
  ad.gridProps = {
    column: areaOptions.isStackedMobile ? 'span 2' : 'auto',
    columnMd: 'auto',
    minWidth: 'min-content',
  };

  return [teaser, ad].filter(isNonNull);
}
