import type { TStoryblock } from '@hubcms/domain-storyblock';
import { isFirstItemWithKey } from '@hubcms/utils-browser';

import { isPersonalizedList, type PersonalizedList } from '../domain/personalized-list';

export function getPersonalizedArticleListsFromStoryblocks(storyblocks: TStoryblock[]): PersonalizedList[] {
  return storyblocks
    .map(({ unflattenedTeaserAreas }) => Object.values(unflattenedTeaserAreas))
    .flat(2)
    .filter(isPersonalizedList)
    .filter(isFirstItemWithKey('id'));
}
