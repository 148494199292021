import type { ElementsFragment } from '@hubcms/domain-cook';
import type { TableData, TableStoryElement } from '@hubcms/domain-story-elements';

import { getElementField } from '../get-element-field';

import type { ElementDataMapFn } from './types';

export const mapTableData: ElementDataMapFn<TableStoryElement> = data => {
  const values = getTableValues(data.fields);
  const rowHeader = getElementField<boolean | string>(data.fields, 'rowheader') || false;
  const columnHeader = getElementField<boolean | string>(data.fields, 'columnheader') || false;
  return {
    firstRowAsHead: typeof rowHeader === 'string' ? rowHeader.toLowerCase() === 'true' : rowHeader,
    firstColumnAsHead: typeof columnHeader === 'string' ? columnHeader.toLowerCase() === 'true' : columnHeader,
    values,
  };
};

function getTableValues(fields: ElementsFragment['fields']) {
  const tableValues = getElementField<string>(fields, 'tableeditor');
  let values: TableData['values'];
  try {
    values = tableValues ? JSON.parse(tableValues) : [];
    if (!Array.isArray(values)) {
      values = [];
    }
  } catch {
    values = [];
  }

  return values;
}
