import cx from 'classnames';
import type { PropsWithChildren, ElementType } from 'react';

import styles from './takeover-ad-content-container.module.scss';

type TakeoverAdContentContainerProps = PropsWithChildren<{
  as?: ElementType;
  className?: string;
}>;

export function TakeoverAdContentContainer({
  as: Component = 'div',
  children,
  className,
  ...props
}: TakeoverAdContentContainerProps) {
  return (
    <Component {...props} className={cx(className, styles.takeoverAdContentContainer)}>
      {children}
    </Component>
  );
}
