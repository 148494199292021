import { useCallback, useMemo } from 'react';

import { enablePersonalizedArticleList } from '@hubcms/brand';
import { useAuth } from '@hubcms/data-access-auth';
import { useCook } from '@hubcms/data-access-cook';
import { useEnv } from '@hubcms/data-access-env';
import type { RecommendationsResponse } from '@hubcms/domain-capitan';
import type { CookData, NewsArticleData } from '@hubcms/domain-cook';
import type { StoryblockFlattenListFn, TStoryblock } from '@hubcms/domain-storyblock';
import type { TeaserPropsOptions } from '@hubcms/domain-teaser';
import { useDidomiConsent } from '@hubcms/utils-didomi';
import { getPageType, getSetGlitrCookieId, getViewId } from '@hubcms/utils-tracking';

import { getRecommendationData } from '../utils/get-recommendation-data';
import { getArticleIdsOnBody } from '../utils/getArticleIdsOnBody';
import { mapPersonalizedArticleLists } from '../utils/map-personalized-article-list';
import { requiredPersonalizationConsentIds } from '../utils/required-personalization-consent-ids';

import { getPersonalizedArticleListsFromStoryblocks } from './getPersonalizedArticleListsFromStoryblocks';

const EMPTY_RECOMMENDATIONS_RESPONSE: RecommendationsResponse = {
  requestId: '',
  lists: [],
  modelVersion: '',
  recommender: '',
};

type UsePersonalizedArticleListHookResult = {
  getFlattenPersonalizedListFn: (abortSignal: AbortSignal) => Promise<StoryblockFlattenListFn>;
};
type UsePersonalizedArticleListHookFn = (
  storyblocks: TStoryblock[],
  teaserPropsOptions: TeaserPropsOptions,
) => UsePersonalizedArticleListHookResult;

const usePersonalizedArticleListsEnabled: UsePersonalizedArticleListHookFn = (storyblocks, teaserPropsOptions) => {
  const { user, isLoading: userIsLoading } = useAuth();
  const cookData: CookData<NewsArticleData> = useCook();
  const env = useEnv();
  const { hasDidomiConsent, isDidomiReady } = useDidomiConsent(requiredPersonalizationConsentIds);

  const identityAccountId = useMemo(() => user?.sub ?? null, [user]);
  const pageType = getPageType(cookData);
  const articleId = cookData.context?.id ?? null;
  const recommendationsApiEndpoint = env.NEXT_PUBLIC_RECOMMENDATIONS_API_ENDPOINT;

  const personalizedArticleLists = useMemo(() => getPersonalizedArticleListsFromStoryblocks(storyblocks), [storyblocks]);

  const getFlattenPersonalizedListFn = useCallback(
    async (abortSignal: AbortSignal) => {
      if (personalizedArticleLists.length === 0 || userIsLoading || !isDidomiReady) {
        return mapPersonalizedArticleLists(EMPTY_RECOMMENDATIONS_RESPONSE, teaserPropsOptions, true);
      }

      if (!hasDidomiConsent) {
        return mapPersonalizedArticleLists(EMPTY_RECOMMENDATIONS_RESPONSE, teaserPropsOptions, false);
      }

      const response = await getRecommendationData(
        {
          apiEndpoint: recommendationsApiEndpoint,
          personalizedArticleLists,
          identityAccountId,
          pageType,
          articleId,
          clientId: getSetGlitrCookieId(),
          viewId: getViewId(),
          excludes: document?.body ? getArticleIdsOnBody(document.body) : [],
        },
        abortSignal,
      );
      return mapPersonalizedArticleLists(response, teaserPropsOptions, false);
    },
    [
      teaserPropsOptions,
      personalizedArticleLists,
      recommendationsApiEndpoint,
      identityAccountId,
      pageType,
      articleId,
      userIsLoading,
      isDidomiReady,
      hasDidomiConsent,
    ],
  );

  return {
    getFlattenPersonalizedListFn,
  };
};

const usePersonalizedArticleListsDisabled: UsePersonalizedArticleListHookFn = () => ({
  getFlattenPersonalizedListFn: useCallback(() => Promise.resolve(teaserArea => teaserArea), []),
});

export const usePersonalizedArticleLists = enablePersonalizedArticleList
  ? usePersonalizedArticleListsEnabled
  : usePersonalizedArticleListsDisabled;
