import { useMemo, useRef, useState } from 'react';
import { useResizeObserver, useWindowSize } from 'usehooks-ts';

import { calculateNumberOfSlots } from './calculateNumberOfSlots';

// NOTE: This functionality assumes all ads inside the wrapper will have the same height as per:
// https://mediahuis.atlassian.net/browse/CW-867?focusedCommentId=99693
// AND https://mediahuis.atlassian.net/browse/CW-867?focusedCommentId=99701
//
// Scrollable ad layout is as illustrated below, assuming VIEWPORT HEIGHT = 12 (units):
// |----AD SLOT------|
// ||--AD ELEMENT---||
// |||--ACTUAL AD--|||
// |||   height:   |||
// |||      4      |||
// |||-------------|||
// ||               ||
// ||               ||
// ||    height:    ||
// || SLOT - ACTUAL ||
// ||   20  -  4    ||
// ||      16       ||
// ||               ||
// ||               ||
// ||               ||
// ||               ||
// ||---------------||
// |     height:     |
// |       20        |
// |-----------------|
// The ACTUAL AD height is the ad height returned by KaChing
// The AD SLOT height is max the VIEWPORT + 2 * ACTUAL AD
// The AD ELEMENT height is AD SLOT - 1 * ACTUAL AD
// This makes an ad sticky for 1 time its height and then completely out of view before the next one is shown
// Thus no 2 ads appear on screen at the same time

export function useScrollableAds() {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const { height: viewportHeight } = useWindowSize();
  const { height: wrapperHeight = 0 } = useResizeObserver({
    ref: wrapperRef,
    box: 'border-box',
  });

  const [actualAdHeight, setActualAdHeight] = useState(0);

  const memoizedCalculculation = useMemo(() => {
    // The maximum height an ad slot can take.
    // In theory this should also substract adStickyTop, but since that can be a CSS variable we cannot calculate with it here
    const maxAdSlotHeight = actualAdHeight ? viewportHeight + actualAdHeight * 2 : 0;

    // These are the number of additonal slots available in the wrapper after the first ad
    const numberOfAdditionalSlots = calculateNumberOfSlots({ wrapperHeight, maxAdSlotHeight, actualAdHeight });

    return {
      maxAdSlotHeight,
      numberOfAdditionalSlots,
    };
  }, [viewportHeight, wrapperHeight, actualAdHeight]);

  return {
    actualAdHeight: `${actualAdHeight}px`,
    maxAdSlotHeight: `${memoizedCalculculation.maxAdSlotHeight}px`,
    numberOfAdditionalSlots: memoizedCalculculation.numberOfAdditionalSlots,
    setActualAdHeight,
    wrapperRef,
  };
}
