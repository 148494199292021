import type { SportsResultData, SportsResultElement } from '@hubcms/domain-story-elements';

import { extractElementFields } from '../element-renderer';

import type { ElementDataMapFn } from './types';

export const mapSportsResult: ElementDataMapFn<SportsResultElement> = data => {
  const { teamOne, teamOneScore, teamTwo, teamTwoScore } = extractElementFields<SportsResultData>(data);

  return {
    teamOne,
    teamOneScore,
    teamTwo,
    teamTwoScore,
  };
};
