import cx from 'classnames';
import { type ComponentProps, type ElementType, Fragment } from 'react';

import type { TStoryblock } from '@hubcms/domain-storyblock';
import type { TeaserPropsOptions } from '@hubcms/domain-teaser';
import type { TeaserGridExtensions } from '@hubcms/domain-teaser-grid';
import { Ad, CbaAd } from '@hubcms/feature-ads';
import { mySectionTeaserGridExtensions } from '@hubcms/feature-my-section';
import { Storyblock } from '@hubcms/ui-storyblock';
import { isCbaAd, useAdPositions } from '@hubcms/utils-ads';
import { isThemed } from '@hubcms/utils-theme';

import { useStoryblockGridData } from '../utils/useStoryblockGridData';

import styles from './storyblock-list.module.scss';

type StoryblockListProps = {
  storyblocks: TStoryblock[];
  adPositions?: number[];
  adFormats?: string[];
  adSlotPrefix?: string;
  isAside?: boolean;
  hasTopMargin?: boolean;
  gridExtensions?: TeaserGridExtensions;
  teaserPropsOptions: TeaserPropsOptions;
  getAdElement?: (
    index: number,
  ) => ElementType<Pick<ComponentProps<typeof Ad>, 'adFormat' | 'adSlot' | 'adStickyTop' | 'adSlotPrefix' | 'className'>>;
};

export function StoryblockList({
  storyblocks,
  adPositions = [],
  adFormats = [],
  adSlotPrefix,
  isAside = false,
  hasTopMargin = false,
  gridExtensions,
  teaserPropsOptions,
  getAdElement = () => Ad,
}: StoryblockListProps) {
  const { getAdFormatForPosition } = useAdPositions(adPositions, adFormats);
  const { storyblockGridData } = useStoryblockGridData(storyblocks, teaserPropsOptions);

  return (
    <div
      className={cx(styles.storyblockList, {
        [styles.withTopMargin]: hasTopMargin,
        [styles.withLastFillsRemainingSpace]: isAside,
      })}
    >
      {storyblockGridData.map(({ theme, groupId, groupType, isHiddenOnMobile, gridData }, idx) => {
        // increasing adSlot index by 1 because of the leaderboard at the top of the page. also, the ad positions in CUE begin their count at 1 instead of 0.
        const adFormat = getAdFormatForPosition(idx + 1);
        const AdElement = getAdElement(idx);

        return (
          <Fragment key={groupId}>
            <Storyblock
              groupId={groupId}
              theme={theme}
              isTransparentTheme={isAside}
              gridData={gridData}
              adElement={Ad}
              hasNoPaddingInline={isAside && !isThemed(theme)}
              gridExtensions={{ ...gridExtensions, ...mySectionTeaserGridExtensions }}
              groupType={groupType}
            />
            {adFormat &&
              (isCbaAd(adFormat) ? (
                <CbaAd
                  className={cx(styles.ad, {
                    [styles.hideOnMobile]: isHiddenOnMobile,
                  })}
                />
              ) : (
                <AdElement
                  adFormat={adFormat}
                  adSlot="b"
                  adSlotPrefix={adSlotPrefix}
                  className={cx(styles.ad, {
                    [styles.hideOnMobile]: isHiddenOnMobile,
                  })}
                />
              ))}
          </Fragment>
        );
      })}
    </div>
  );
}
