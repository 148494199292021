import {
  type ArticleTeaserData,
  type HtmlTeaserData,
  type NewsletterTeaserData,
  type PlaceholderTeaserData,
  type TeaserData,
  isNewsletterTeaserData,
} from '@hubcms/domain-teaser';

import { isHtmlOrArticleOrPlaceholderTeaserData } from './isArticleOrPlaceholderTeaserData';

export function isCustomizableStoryblockTeaserData(
  teaserData: TeaserData,
): teaserData is HtmlTeaserData | ArticleTeaserData | PlaceholderTeaserData | NewsletterTeaserData {
  return isHtmlOrArticleOrPlaceholderTeaserData(teaserData) || isNewsletterTeaserData(teaserData);
}
