import {
  defaultTeaserImageRatio,
  breakingStoryblockHasDateTime,
  breakingStoryblockMiniTeaserHasImage,
  breakingStoryblockMiniTeaserHasLabel,
  breakingStoryblockHeadTeaserImageRatio,
} from '@hubcms/brand';
import type { TeaserImageFormat } from '@hubcms/domain-images';
import type { BreakingGroupOptions } from '@hubcms/domain-storyblock';
import type { PerViewport } from '@hubcms/domain-styling';
import { isArticleTeaserData } from '@hubcms/domain-teaser';
import type { GDefaultTeaser, GGrid, GTextOnImageTeaser } from '@hubcms/domain-teaser-grid';

import type { MapStoryblockFn } from '../../domain/map-storyblock-fn';

const MAX_NUMBER_OF_TEASERS = 4;

function getPreferredImageFormatForHeadTeaser(type: string): TeaserImageFormat | PerViewport<TeaserImageFormat> {
  if (type === 'podcast') {
    return 'oneOne';
  }
  return {
    xs: breakingStoryblockHeadTeaserImageRatio,
    md: 'sixteenNine',
  };
}

function getPreferredImageFormatForMiniTeaser(type: string): TeaserImageFormat {
  if (type === 'podcast') {
    return 'oneOne';
  }
  return defaultTeaserImageRatio;
}

export const mapBreakingGroup: MapStoryblockFn<BreakingGroupOptions> = (_, teaserAreas) => {
  const articleTeasers = teaserAreas.groupArea.filter(isArticleTeaserData).slice(0, MAX_NUMBER_OF_TEASERS);

  const firstTeaserData = articleTeasers[0];
  const headTeaser: GTextOnImageTeaser | null = firstTeaserData
    ? {
        type: 'text-on-image-teaser',
        data: {
          teaserData: {
            ...firstTeaserData,
          },
          imageFormat: getPreferredImageFormatForHeadTeaser(firstTeaserData.contentType),
          hasIntro: false,
          hasDateTime: breakingStoryblockHasDateTime,
          isPriority: true,
          size: 'lg',
          trackingData: {
            ...firstTeaserData.trackingData,
            clickitemposition: 1,
          },
        },
        gridProps: {
          minWidth: {
            sm: '280px',
          },
        },
      }
    : null;
  const miniTeasers: GDefaultTeaser[] = articleTeasers.slice(1).map((teaserData, index) => ({
    type: 'default-teaser',
    data: {
      teaserData: {
        ...teaserData,
        label: breakingStoryblockMiniTeaserHasLabel ? teaserData.label : '',
        subLabel: breakingStoryblockMiniTeaserHasLabel ? teaserData.subLabel : '',
      },
      imageSizes: {
        sm: '384px',
        md: '512px',
        lg: '640px',
      },
      imageFormat: getPreferredImageFormatForMiniTeaser(teaserData.contentType),
      size: 'sm',
      hasInset: true,
      hasDateTime: breakingStoryblockHasDateTime,
      hasIntro: false,
      orientation: 'horizontal',
      isImageHidden: !breakingStoryblockMiniTeaserHasImage,
      isFullHeight: true,
      trackingData: {
        ...teaserData.trackingData,
        clickitemposition: 2 + index,
      },
    },
    gridProps: {
      minWidth: {
        sm: '280px',
      },
    },
  }));

  const miniTeasersGrid = miniTeasers.length > 0 ? createMiniTeasersGrid(miniTeasers) : null;

  return {
    key: 'groupArea',
    theme: 'breaking',
    gridColumns: 1,
    gridColumnsMd: 1,
    gridColumnsLg: 1,
    gridAutoRows: 'min-content',
    hasRowGap: false,
    hasInlinePadding: false,
    hasNoGridDivider: true,
    items: headTeaser ? [headTeaser, ...(miniTeasersGrid ? [miniTeasersGrid] : [])] : [],
  };
};

function createMiniTeasersGrid(miniTeasers: GDefaultTeaser[]): GGrid {
  const isSingleMiniTeaser = miniTeasers.length === 1;
  return {
    type: 'grid',
    data: {
      items: miniTeasers,
      gridColumns: miniTeasers.length,
      gridColumnsMd: isSingleMiniTeaser ? 3 : miniTeasers.length,
      gridColumnsLg: 3,
      scroll: {
        hasPadding: true,
        hasNoPaddingTop: true,
        breakpoints: {
          xs: true,
          sm: true,
          md: false,
          lg: false,
          xl: false,
        },
      },
      hasInlinePadding: false,
      hasNoGridDivider: true,
      bgColor: 'var(--breaking-fill)',
    },
    gridProps: {
      column: '1 / -1',
    },
  };
}
