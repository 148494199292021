import type { TGridProps, TTeaserGridItem } from '@hubcms/domain-teaser-grid';

import type { BaseParams } from './types';

function moveToLastColumn<T extends TTeaserGridItem<string, unknown>>(item: T): T {
  return {
    ...item,
    gridProps: {
      ...item.gridProps,
      columnLg: '-2',
    },
  };
}

function spanLastItemOnTwoRows<T extends TTeaserGridItem<string, unknown>>(item: T, idx: number, arr: T[]): T {
  if (idx !== arr.length - 1) {
    return item;
  }
  return {
    ...item,
    gridProps: {
      ...item.gridProps,
      rowLg: `span 2`,
    },
  };
}

function calculateListHeight(
  params: BaseParams & {
    isAreaOneWithIntro: boolean;
  },
): number {
  let lgBlockHeight = 2;

  if (params.hasGroupTitle) {
    lgBlockHeight++;
  }

  if (params.areaOnePosition === 'top') {
    lgBlockHeight++;
  }
  if (params.isAreaOneWithIntro) {
    lgBlockHeight++;
  }
  return lgBlockHeight;
}

function getAdRowLg({ areaOnePosition, hasGroupTitle, hasAreaThreeTitle }: BaseParams): TGridProps['rowLg'] {
  if (hasGroupTitle) {
    return areaOnePosition === 'left' || areaOnePosition === 'middle' ? '2 / 4' : '2 / 5';
  }
  if (hasAreaThreeTitle) {
    return areaOnePosition === 'left' || areaOnePosition === 'middle' ? '2 / 3' : '2 / 4';
  }
  return areaOnePosition === 'left' || areaOnePosition === 'middle' ? '1 / 3' : '1 / 4';
}

export const areaThreeUtils = {
  calculateListHeight,
  getAdRowLg,
  spanLastItemOnTwoRows,
  moveToLastColumn,
};
