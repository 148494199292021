import type { LifestyleGroupOptions } from '@hubcms/domain-storyblock';

import type { CreateStoryblockOptionsFn } from '../../domain/create-storyblock-options-fn';
import { mapToGroupOptionsRecord } from '../../lib/mapToGroupOptionsRecord';
import { getIsHiddenOnMobile } from '../getIsHiddenOnMobile';

export const createLifestyleGroupOptions: CreateStoryblockOptionsFn<LifestyleGroupOptions> = ({ group, sectionParams }) => {
  const groupOptions = mapToGroupOptionsRecord(group.groupOptions);

  return {
    type: 'LifestyleGroup',
    isHiddenOnMobile: getIsHiddenOnMobile(groupOptions),
    legacyGroupOptions: groupOptions,
    actionText: sectionParams['section.group.readmore.text'],
  };
};
