import { type ElementsFragment, type SectionParams, isElementWithChildren } from '@hubcms/domain-cook';
import type { InfoBlockData, InfoBlockStoryElement, StoryElementType } from '@hubcms/domain-story-elements';
import { isNonNull } from '@hubcms/utils-browser';

import { extractElementFields } from '../element-renderer';
import { isValidStoryElement } from '../is-valid-story-element';

import type { ElementDataMapFnWithOptions, RecursiveMapFn } from './types';

const VALID_INFOBLOCK_ELEMENTS: StoryElementType[] = [
  'infoblock_headline',
  'infoblock_paragraph',
  'infoblock_image',
  'page_break',
  'infoblock_sub_head',
  'infoblock_list_numbered',
  'infoblock_list_bulleted',
  'sports_result',
  'rating',
];
type InfoBlockItem = InfoBlockData['items'][number];

function getBackgroundColor(sectionParams: SectionParams, frontendTemplate?: string | null, theme?: InfoBlockData['theme']) {
  if (theme === 'sport') {
    return 'var(--semantic-background-accent-adaptive-soft-fill)';
  }
  return (
    sectionParams[`article.infoblock.bg.${frontendTemplate}`] ||
    sectionParams['article.infoblock.bg.default-article'] ||
    'var(--color-primary-10)'
  );
}

export const mapInfoBlockGroup: ElementDataMapFnWithOptions<
  'sectionParams' | 'frontendTemplate' | 'elements' | 'recursiveMapFn',
  InfoBlockStoryElement
> = (data, { sectionParams, frontendTemplate, elements, recursiveMapFn }) => {
  const theme = data.type === 'sports_group' ? 'sport' : undefined;
  const backgroundColor = getBackgroundColor(sectionParams, frontendTemplate, theme);

  const children = isElementWithChildren(data) ? data.children.map(childId => elements.find(({ id }) => id === childId)) : [];

  const childStoryElements = mapChildElementsToInfoblockItems(children.filter(isNonNull), recursiveMapFn);

  type InfoBlockFields = { collapseOnMobile: string; collapseOnMobileAfter: number };
  const { collapseOnMobile, collapseOnMobileAfter } = extractElementFields<InfoBlockFields>(data);
  const expandButtonText = sectionParams[`article.infoblock.summary.collapse.expand.text`] ?? null;
  const fallbackElementCount = sectionParams[`article.infoblock.summary.collapse.expand.elementcount`];
  const fallbackCollapseOnMobileAfter = fallbackElementCount ? Number.parseInt(fallbackElementCount, 10) : null;

  return {
    backgroundColor,
    theme,
    items: childStoryElements,
    charCount: childStoryElements.reduce((total, currentElement) => total + (currentElement.charCount ?? 0), 0),
    collapseOnMobileAfter: collapseOnMobile === 'true' ? (collapseOnMobileAfter ?? fallbackCollapseOnMobileAfter) : null,
    expandButtonText,
  };
};

function mapChildElementsToInfoblockItems(children: ElementsFragment[], recursiveMapFn: RecursiveMapFn): InfoBlockItem[] {
  return children.map(recursiveMapFn).filter(isValidStoryElement<InfoBlockItem>(VALID_INFOBLOCK_ELEMENTS));
}
