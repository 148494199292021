import { type OnNewsletter, isElementWithRelation } from '@hubcms/domain-cook';
import type { NewsletterStoryElement } from '@hubcms/domain-story-elements';
import { mapCookFieldsToNewsletterData } from '@hubcms/utils-newsletter';

import type { ElementDataMapFn } from './types';

export const mapNewsLetterWidget: ElementDataMapFn<NewsletterStoryElement> = data => {
  const newsLetterRelation = isElementWithRelation<OnNewsletter>(data) ? data.relation : null;

  if (!newsLetterRelation || !newsLetterRelation.fields) {
    return null;
  }

  return mapCookFieldsToNewsletterData(newsLetterRelation.fields);
};
