export function calculateNumberOfSlots({
  actualAdHeight,
  maxAdSlotHeight,
  wrapperHeight,
}: Record<'actualAdHeight' | 'maxAdSlotHeight' | 'wrapperHeight', number>) {
  // If the dimensions aren't known yet, add no additional slots
  if (wrapperHeight === 0 || maxAdSlotHeight === 0) {
    return 0;
  }

  // The number of additional slots is the wrapperHeight - actualHeight divided by the maximum a slot can take
  // The `- actualHeight` is there to allow a last ad to take that space without scrolling
  return Math.max(Math.floor((wrapperHeight - actualAdHeight) / maxAdSlotHeight), 0);
}
